<template>
  <div class="realtimeprice">
    <div class="layui-body"
         :style="topnotice">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf ">
          <div class="widget-head fl">
            <div class="widget-title fl">现货指数</div>
            <div class="fl zhu-title">（每日14:30和17:30各更新一次）</div>
            <div class="fr fr-title">
              <div class="fl pr20">
                <el-button type="primary"
                           @click="getImg">获取截图</el-button>
              </div>
            </div>
          </div>
          <div class="widget-body am-cf"
               ref="mynode">
            <div class="spotindex-title">
              <div class="daohuo">{{date}} 广州市理计到货价格指数
                <br>
                <span class="cankou">参考价-{{area}}，到货地址为广州天河</span>
              </div>
            </div>
            <div class="luowengang">
              <table class="layui-table">
                <colgroup>
                  <col width="60">
                  <col>
                  <col>
                  <col width="70">
                  <col>
                  <col>
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="6">螺纹钢18-25E</th>
                  </tr>
                  <tr>
                    <th>序号</th>
                    <th>品牌</th>
                    <th>到货价</th>
                    <th>配送</th>
                    <th>参考价</th>
                    <th>价差</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in luowengangData"
                      :key="item.id">
                    <td>{{index+1}}</td>
                    <td>{{item.brand}}</td>
                    <td>
                      <div><span>{{item.newest_result_price}}</span><span v-html="item.newest_result_price_float"></span></div>
                    </td>
                    <td><span v-html="item.transport"></span></td>
                    <td>
                      <div><span>{{item.newest_web_price}}</span> <span v-html="item.newest_web_price_float"></span></div>
                    </td>
                    <td>
                      <div><span>{{item.newest_diff_price}}</span> <span v-html="item.newest_diff_price_float"></span></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="panluo">
              <table class="layui-table">
                <colgroup>
                  <col width="60">
                  <col>
                  <col>
                  <col width="70">
                  <col>
                  <col>
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="6">盘螺8-10E</th>
                  </tr>
                  <tr>
                    <th>序号</th>
                    <th>品牌</th>
                    <th>到货价</th>
                    <th>配送</th>
                    <th>参考价</th>
                    <th>价差</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in panluoData"
                      :key="item.id">
                    <td>{{index+1}}</td>
                    <td>{{item.brand}}</td>
                    <td>
                      <div><span>{{item.newest_result_price}}</span><span v-html="item.newest_result_price_float"></span></div>
                    </td>
                    <td><span v-html="item.transport"></span></td>
                    <td>
                      <div><span>{{item.newest_web_price}}</span> <span v-html="item.newest_web_price_float"></span></div>
                    </td>
                    <td>
                      <div><span>{{item.newest_diff_price}}</span> <span v-html="item.newest_diff_price_float"></span></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="gaoxian">
              <table class="layui-table">
                <colgroup>
                  <col width="60">
                  <col>
                  <col>
                  <col width="70">
                  <col>
                  <col>
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="6">高线8-10</th>
                  </tr>
                  <tr>
                    <th>序号</th>
                    <th>品牌</th>
                    <th>到货价</th>
                    <th>配送</th>
                    <th>参考价</th>
                    <th>价差</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in gaoxianData"
                      :key="item.id">
                    <td>{{index+1}}</td>
                    <td>{{item.brand}}</td>
                    <td>
                      <div><span>{{item.newest_result_price}}</span><span v-html="item.newest_result_price_float"></span></div>
                    </td>
                    <td><span v-html="item.transport"></span></td>
                    <td>
                      <div><span>{{item.newest_web_price}}</span> <span v-html="item.newest_web_price_float"></span></div>
                    </td>
                    <td>
                      <div><span>{{item.newest_diff_price}}</span> <span v-html="item.newest_diff_price_float"></span></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="bg"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getUsuallySizePriceNewest, getWebArea, getUserNotice } from '@/api/global'
import domtoimage from 'dom-to-image'
export default {
  data () {
    return {
      luowengangData: [],
      panluoData: [],
      gaoxianData: [],
      area: '',
      date: '',
      isstatus: 0,
      topnotice: ''
    }
  },
  mounted () {
    this.loadgetUserNotice()
    this.getWebArea()
    this.searchData()
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.topnotice = 'top: 92px;'
          } else {
            this.isstatus = 0
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getWebArea () {
      getWebArea().then(res => {
        this.area = res.data.area
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    searchData () {
      getUsuallySizePriceNewest().then(res => {
        if (res.data.status === 'success') {
          this.date = res.data.data.date
          this.luowengangData = res.data.data.luowengang_date_newest.map(val => {
            if (val.newest_result_price_float > 0) {
              val.newest_result_price_float = '<span class="red_font">↑' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float < 0) {
              val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float === 0) {
              val.newest_result_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_web_price_float > 0) {
              val.newest_web_price_float = '<span class="red_font">↑' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float < 0) {
              val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float === '-') {
              val.newest_web_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_diff_price_float > 0) {
              val.newest_diff_price_float = '<span class="red_font">↑' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float < 0) {
              val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float === 0) {
              val.newest_diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.transport === '直送') {
              val.transport = '<span class="el-tag el-tag--light el-tag--mini">直送</span>'
            } else {
              val.transport = '<span class="el-tag el-tag--warning el-tag--mini">仓提</span>'
            }
            return val
          })
          this.gaoxianData = res.data.data.gaoxian_date_newest.map(val => {
            if (val.newest_result_price_float > 0) {
              val.newest_result_price_float = '<span class="red_font">↑' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float < 0) {
              val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float === 0) {
              val.newest_result_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_web_price_float > 0) {
              val.newest_web_price_float = '<span class="red_font">↑' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float < 0) {
              val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float === '-') {
              val.newest_web_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_diff_price_float > 0) {
              val.newest_diff_price_float = '<span class="red_font">↑' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float < 0) {
              val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float === 0) {
              val.newest_diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.transport === '直送') {
              val.transport = '<span class="el-tag el-tag--light el-tag--mini">直送</span>'
            } else {
              val.transport = '<span class="el-tag el-tag--warning el-tag--mini">仓提</span>'
            }
            return val
          })
          this.panluoData = res.data.data.panluo_date_newest.map(val => {
            if (val.newest_result_price_float > 0) {
              val.newest_result_price_float = '<span class="red_font">↑' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float < 0) {
              val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float === 0) {
              val.newest_result_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_web_price_float > 0) {
              val.newest_web_price_float = '<span class="red_font">↑' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float < 0) {
              val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float === '-') {
              val.newest_web_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_diff_price_float > 0) {
              val.newest_diff_price_float = '<span class="red_font">↑' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float < 0) {
              val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float === 0) {
              val.newest_diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.transport === '直送') {
              val.transport = '<span class="el-tag el-tag--light el-tag--mini">直送</span>'
            } else {
              val.transport = '<span class="el-tag el-tag--warning el-tag--mini">仓提</span>'
            }
            return val
          })
        } else {
          this.$message.error('查询不到数据')
        }
      }).catch(err => {
        console.log('获取数据失败', err)
      })
    },
    getImg () {
      const loading = this.$loading({
        lock: true,
        text: '正在截图中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      var node = this.$refs.mynode
      var seft = this
      domtoimage.toPng(node).then(function (dataUrl) {
        var img = new Image()
        img.src = dataUrl
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = seft.date + 'new-steel-data.png' || '下载图片名称'
        a.href = dataUrl
        a.dispatchEvent(event)
        loading.close()
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    },
    getExcel () {
      // var a = document.createElement('a')
      // var event = new MouseEvent('click')
      // a.download = _this.newestDataDate.value +'new-steel-data.png' || '下载图片名称';
      // a.href = 'dingoapi/export_market_data'
      // a.dispatchEvent(event)
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    luowengangcolumnStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 4) {
        // return 'background:rgba(225, 243, 216,0.3);'
      }
    },
    panluocolumnStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 4) {
        // return 'background:rgba(250, 236, 216,0.3);'
      }
    },
    gaoxiancolumnStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 4) {
        // return 'background:rgba(253, 226, 226,0.3);'
      }
    }
  }
}
</script>

<style lang="less"  >
.layui-table td,
.layui-table th {
  text-align: center !important;
  font-weight: 600 !important;
}
.layui-table thead tr {
  color: #ffffff;
  background-color: #707889 !important;
}
.red_font {
  color: #aa1010;
}
.green_font {
  color: #10aa10;
}
.yellow_font {
  color: #aaaa10;
}
.sm_width {
  width: 80px;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.luowengang {
  float: left;
  width: 33.33333%;
}
.panluo {
  float: left;
  width: 33.33333%;
}
.gaoxian {
  float: left;
  width: 33.33333%;
}
.spotindex-title {
  float: left;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.pr20 {
  padding-right: 20px;
}
.bg {
  width: 100%;
  height: 80%;
  z-index: 10;
  top: 18%;
  position: absolute;
  background: url('../assets/images/back.png');
}
.cankou {
  font-size: 12px;
}
.daohuo {
  font-size: 18px;
  font-weight: 900;
}
.zhu-title {
  font-size: 14px;
  padding-left: 5px;
}
</style>
